import React from 'react'
import { Tooltip, Badge, Affix, message, Col, Row, Input, Icon, BackTop, Popover } from 'antd'
import { trackEvent } from 'lib'
import './index.less'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileMenu: false,
    }
  }
  componentDidMount() {}
  handleToggleMenu() {
    const { showMobileMenu } = this.state
    this.setState({
      showMobileMenu: !showMobileMenu
    })
  }
  handleTo(name) {
    trackEvent('header', name)
    message.warn('即将上线')
    return
  }
  render() {
    const { children } = this.props
    const { showMobileMenu } = this.state
    return (
      <div className='root-header'>
        <div className='root-header-container'>
          <Row style={{ width: '100%' }} type="flex" justify="space-between" align='middle'>
            <Col lg={ 8 } xs={ 15 } sm={ 15 } md={ 15 } >
              <div className='root-header-container-brand'>
                <div className='root-header-container-brand-name'>古调</div>
                <div className='root-header-container-brand-tips'>
                  <div>虽自爱, 今人多不弹</div>
                  <div className='root-header-container-brand-tips-domain'>Gu Diao.com.cn</div>
                </div>
              </div>
            </Col>

            <Col lg={ 8 } xs={ 0 } sm={ 0 } md={ 0 } >
              <div className='root-header-container-guide'>
                <a href='#' onClick={ () => this.handleTo('tang') }>唐诗</a>
                <span></span>
                <a href='#' onClick={ () => this.handleTo('song') }>宋诗</a>
                <span></span>
                <a href='#' onClick={ () => this.handleTo('ci') }>宋词</a>
                <span></span>
                <a href='#' onClick={ () => this.handleTo('qu') }>元曲</a>
              </div>
            </Col>
            <Col  lg={ 0 } xs={ 8 } sm={ 8 } md={ 8 }>
              <div onClick={ this.handleToggleMenu.bind(this) } className='root-header-container-tools' style={{ padding: '.2rem 0' }}>
                <Icon type={ showMobileMenu ? 'close' : 'menu' } />
              </div>
            </Col>
          </Row>
        </div>
        {
          showMobileMenu && (
            <div className='root-header-menu'>
              <a href='/'><div className='root-header-menu-item'><Icon type="home" /> 首页</div></a>
              <a href='/kw'><div className='root-header-menu-item'><Icon type="search" /> 探索</div></a>
              <a href='/articles'><div className='root-header-menu-item'><Icon type="bars" /> 文章</div></a>
              <a href='/quan'><div className='root-header-menu-item'><Icon type="smile" /> 圈子</div></a>
              <a href='/gpt'><div className='root-header-menu-item'><Icon type="robot" /> GPT</div></a>
            </div>
          )
        }
      </div>
    )
  }
}
export default Header
