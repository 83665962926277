import React from 'react'
import { message, Modal } from 'antd'
import { send, get } from 'mulan-lib'
import { processRequest, trackEvent, setTitle } from 'lib'
import './index.less'

class Active extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0
    }
  }
  componentDidMount() {
    this.handleGetCount()
    // setInterval(() => {
    //   this.handleGetCount()
    // }, 3000)
  }
  handleGetCount(cb) {
    processRequest(
      send('/api/admin/common/func', {
        url: 'tang/support/get',
        data: {}
      }),
      data => {
        this.setState({
          count: data
        })
        if (cb) {
          cb(data)
        }
      })
  }
  handleSupport() {
    trackEvent('active', 'support')
    processRequest(
      send('/api/admin/common/func', {
        url: 'tang/support',
        data: {}
      }),
      data => {
        this.handleGetCount(count => {
          Modal.success({
            content: (
              <div>
                <div style={{  }}>{ `您是第 ${ count } 个支持者，感谢支持！` }</div>
                <div style={{ margin: '.12rem 0', color: '#888', fontSize: '.12rem', paddingLeft: '.38rem' }} >收藏本站 Ctrl + D（Mac: ⌘ + D）</div>
                <div style={{ margin: '.12rem 0', color: '#888', fontSize: '.12rem', paddingLeft: '.38rem' }} >网站火速开发中，即将上线，<span style={{ color: '#000' }}>求分享 orz</span></div>
              </div>
            ),
          })
        })

      })
  }
  render() {
    const { count } = this.state

    return (
      <div className='active'>
        <div>古诗文 · 薪火计划</div>

        <div className='active-content'>
          {
            `许多人熟知的古诗文只是冰山一角
            还有更多优秀之作值得发掘
            这些文字是历史的见证，蕴含着丰富的情感与智慧
            因为它们，我们的文化永存
            古诗文·薪火计划
            需要您的支持`.split('\n').map(o => {
              return (<div key={ o } className='active-content-item'>{o}</div>)
            })
          }
        </div>
        <div className='active-count'>🔥 { count } 🔥</div>
        <div className='flex flex-justify-center flex-align-center'>
          <div className='active-btn' onClick={ this.handleSupport.bind(this) }>支持一下</div>
        </div>
      </div>
    )
  }
}

export default Active
