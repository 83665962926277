import React from 'react'
import { Tooltip, Badge, Affix, message, Col, Row, Input, Icon, BackTop, Popover } from 'antd'
import Header from './_header'
import './index.less'


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileMenu: false,
      isRootPage: true,
    }
  }
  componentDidMount() {
    const { location } = this.props
    const { query, pathname } = location
    const { q } = query
 // console.log('isRootPage', pathname === '/', pathname)
    this.setState({
      isRootPage: pathname === '/',
      rootPath: pathname
    })
  }
  static getDerivedStateFromProps(props, state) {
    const pathname = props.location.pathname
    if (pathname != state.rootPath) {
      return {
        ...state,
        rootPath: pathname,
        isRootPage: pathname === '/'
      }
    }
    return null
  }
  handleToggleMenu() {
    const { showMobileMenu } = this.state
    this.setState({
      showMobileMenu: !showMobileMenu
    })
  }
  render() {
    const { children } = this.props
    const { showMobileMenu, isRootPage } = this.state
    return (
      <div className='root'>
        <Header />
        <div className='root-body'>
          <div className={ `root-body-content ${ isRootPage ? '' : 'child-page' } ` }>{ children }</div>
          { !isRootPage && (<div className='root-body-sider'>123</div>) }
        </div>
        <div className='root-footer'>
        </div>
        <BackTop target={ () => window.document.body } />
      </div>
    )
  }
}
export default Home
