import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover, Button } from 'antd'
import { send, get } from 'mulan-lib'
import { sify } from 'chinese-conv'

import { processRequest, trackEvent, setTitle } from 'lib'
import Active from './active'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      dynasty: '唐'
    }
    setTitle('古调')
  }
  componentDidMount() {
    this.rank()
  }
  rank() {
    trackEvent('home', 'roll')
    processRequest(
      send('/api/admin/common/func', {
        url: 'tang/rank',
        data: {
          type: '300'
        }
      }),
      data => {
        setTimeout(() => {
          const { contents, title, paragraphs, author = '佚名' } = data
          const _title = title.replace(' ', '·')
          this.setState({
            loading: false,
            title: _title.length > 15 ? _title.substr(0, 15).concat(' ⋮ ') : _title,
            dynasty: '唐',
            author,
            paragraphs,
          })
        }, 1500)

      }
    )
  }
  handleToMore() {
    trackEvent('home', 'more')
    message.warn('即将上线')
    return
  }
  roll() {
    this.setState({
      word: '加载中...',
      wordId: '',
      loading: true,
    }, () => {
      this.rank()
    })
  }
  render() {
    const { word = '', loading = true, title = '', dynasty = '', author = '', paragraphs = []  } = this.state
    const len = paragraphs.length
    return (
      <div className='home'>
        <div className={ `main ${ loading  ? 'loading' : '' }` }>
          <div className='main-rank flex flex-justify-center'>
            {
              loading && (<div className='main-rank-loading'>{word}</div>)
            }
            <div className='main-rank-name'>{ sify(title) }</div>
            <div className='main-rank-info-mobile'>
              <span className='main-rank-info-mobile-dynasty'>{dynasty}</span>
              <span className='main-rank-info-mobile-author'>{ sify(author) }</span>
            </div>
            <div className='main-rank-content'>

              <div>
                {
                  paragraphs.slice(0, 6).map(o => {
                    return <p key={o}>{sify(o)}</p>
                  })
                }
                {
                  len > 6 && (
                    <div className='main-rank-content-more'>
                      <a href='#' onClick={ this.handleToMore.bind(this) }>{ `剩余${ len - 6 }句，查看完整` }</a>
                    </div>)
                }
              </div>
            </div>
            <div className='main-rank-info'>
              <span className='main-rank-info-dynasty'>{dynasty}</span>
              <span className='main-rank-info-author'>{ sify(author) }</span>
            </div>
          </div>
          <div className='main-action flex flex-justify-center flex-align-center'>
            <div className={`main-action-roll ${ loading ? 'loading' : '' } `} onClick={ this.roll.bind(this) } >
              { loading ? (<Icon type="loading" />) : '换一首' }
            </div>
          </div>
          <div className='main-tips'>古调</div>
        </div>

        <Active />
        <div className='main-footer'><a href='https://beian.miit.gov.cn/' target='_blank' >沪 ICP 备 2020033875 号-3</a></div>
      </div>
    )
  }
}

export default Home
